<template>
  <div>
    <div v-if="usedRefs.length == 0">
      <b-row>
        <b-col sm="auto">
          {{ $t("components.tei.isOrnamental") }}
        </b-col>
        <b-col sm="auto">
          <b-form-checkbox
            v-model="content.ornamental"
            name="ornamental-checkbox"
            switch
            @change="changed"
          >
            {{
              content.ornamental
                ? $t("components.tei.ornamental")
                : $t("components.tei.notOrnamental")
            }}
          </b-form-checkbox>
        </b-col>
        <b-col v-if="!content.ornamental">
          <b-button
            v-t="'components.tei.addBlock'"
            variant="outline-primary"
            @click="addRef"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row align-h="between" align-v="center">
        <b-col v-if="content && usedRefs.length > 1" cols="md-auto">
          <label for="variant-selector" class="sr-only">
            {{ $t("components.tei.show") }}
          </label>
          <b-form-select id="variant-selector" v-model="currentRef">
            <option v-for="r in usedRefs" :key="r" :value="r">
              <span v-t="'components.tei.referencePrefix'"></span>
              <span> {{ r }}</span>
            </option>
          </b-form-select>
        </b-col>
        <b-col v-else cols="md-auto">
          <p class="mb-0">{{ $t("components.tei.onlyOneVariant") }}</p>
        </b-col>
        <b-col cols="md-auto">
          <b-button
            v-t="'components.tei.addBlock'"
            variant="outline-primary"
            @click="addRef"
          />
        </b-col>
      </b-row>
      <hr />
      <b-list-group v-if="runicLanguages.length > 0" class="previews" flush>
        <b-list-group-item>
          <h3 class="h5">{{ $t("components.tei.transliteration") }}</h3>
          <tei-preview
            :text="currentReading.teiText"
            :no-preview-text="$t('components.tei.incompleteMarkup')"
            preview-type="rundata"
            class="tei-preview-current font-weight-bold"
          />
        </b-list-group-item>
        <b-list-group-item
          v-for="(item, index) in currentInterpretations"
          :key="'preview-' + index"
        >
          <h3 class="h5">
            {{ runicLanguages.find(x => x.id == item.language.id).sv }}
          </h3>
          <tei-word-count-warning
            :tei-primary="currentReading.teiText"
            :tei-reference="item.teiText"
          />
          <tei-preview
            :text="item.teiText"
            :no-preview-text="$t('components.tei.incompleteMarkup')"
            preview-type="rundata"
            class="tei-preview-current font-italic"
          />
        </b-list-group-item>
      </b-list-group>
      <hr />
      <b-card no-body>
        <b-tabs no-fade card>
          <div
            v-for="(item, index) in content.readings"
            :key="'reading' + index"
          >
            <b-tab v-if="item.reference == currentRef" active>
              <template slot="title">
                <span>{{ $t("components.tei.transliteration") }}</span>
              </template>
              <tei-editor v-model="item.teiText" @change="changed" />
              <hr />
              <plain-text v-model="item.text" @change="changed" />
              <hr />
              <sources-selector
                v-if="item['sources']"
                :id="'reading-' + index + '-sources'"
                v-model="item['sources']"
                @change="changed"
              />
            </b-tab>
          </div>
          <div
            v-for="(r, rindex) in runicOrder"
            :key="'orderedinterpretation' + rindex"
          >
            <div
              v-for="(item, index) in content.interpretations"
              :key="'interpretation' + index"
            >
              <b-tab
                v-if="
                  item.readingReference == currentRef && item.language.id == r
                "
              >
                <template slot="title">
                  <span v-if="runicLanguages.length > 0">{{
                    runicLanguages.find(x => x.id == item.language.id).sv
                  }}</span>
                </template>
                <tei-editor v-model="item.teiText" @change="changed" />
                <hr />
                <plain-text v-model="item.text" @change="changed" />
                <hr />
                <sources-selector
                  v-if="item['sources']"
                  :id="'reading-' + index + '-sources'"
                  v-model="item['sources']"
                  @change="changed"
                />
              </b-tab>
            </div>
          </div>
          <div
            v-for="(m, mindex) in modernOrder"
            :key="'orderedtranslations' + mindex"
          >
            <div
              v-for="(item, index) in content.translations"
              :key="'translation' + index"
            >
              <b-tab
                v-if="
                  item.readingReference == currentRef && item.language.id == m
                "
              >
                <template slot="title">
                  <span v-if="modernLanguages.length > 0">{{
                    modernLanguages.find(x => x.id == item.language.id).sv
                  }}</span>
                </template>
                <tei-editor v-model="item.teiText" @change="changed" />
                <hr />
                <plain-text v-model="item.text" @change="changed" />
                <hr />
                <sources-selector
                  v-if="item['sources']"
                  :id="'reading-' + index + '-sources'"
                  v-model="item['sources']"
                  @change="changed"
                />
              </b-tab>
            </div>
          </div>
          <b-tab v-if="runicLanguages.length > 0 && modernLanguages.length > 0">
            <template slot="title">
              {{ $t("components.texts.add") }}
            </template>
            {{ $t("components.texts.interpretations") }}
            <b-row>
              <b-col>
                <b-form-select v-model="runicSelect">
                  <b-form-select-option
                    v-for="(item, index) in runicOrder"
                    :key="'runicSelect-' + index"
                    :value="item"
                    :disabled="
                      usedLangs[currentRef].interpretations.includes(item)
                    "
                  >
                    {{ runicLanguages.find(x => x.id == item).sv }}
                    {{
                      usedLangs[currentRef].interpretations.includes(item)
                        ? $t("components.texts.exists")
                        : ""
                    }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col sm="auto">
                <b-button
                  v-t="'components.texts.addButton'"
                  @click="addRunic"
                />
              </b-col>
            </b-row>
            {{ $t("components.texts.translations") }}
            <b-row>
              <b-col>
                <b-form-select v-model="modernSelect">
                  <b-form-select-option
                    v-for="(item, index) in modernOrder"
                    :key="'modernSelect-' + index"
                    :value="item"
                    :disabled="
                      usedLangs[currentRef].translations.includes(item)
                    "
                  >
                    {{ modernLanguages.find(x => x.id == item).sv }}
                    {{
                      usedLangs[currentRef].translations.includes(item)
                        ? $t("components.texts.exists")
                        : ""
                    }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col sm="auto">
                <b-button
                  v-t="'components.texts.addButton'"
                  @click="addModern"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "TextsComponent",
  components: {
    TeiEditor: () => import("./TeiEditor"),
    PlainText: () => import("./TeiEditor/PlainText"),
    TeiWordCountWarning: () => import("./TeiEditor/TeiWordCountWarning"),
    TeiPreview: () => import("./TeiEditor/TeiPreview.vue"),
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      readingReferences: [
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "AA",
        "AE",
        "OE"
      ],
      currentRef: "P",
      modernLanguages: [],
      runicLanguages: [],
      modernOrder: ["en-gb", "sv-se", "da-dk", "nb-no", "nn-no"],
      runicOrder: [
        "FVN",
        "URN",
        "RSV",
        "RDA",
        "FGU",
        "FSV",
        "FDA",
        "FÖN",
        "FNO",
        "FIS",
        "NON",
        "DSV",
        "OVD",
        "NSV",
        "LAT",
        "und",
        "NFS"
      ],
      modernSelect: null,
      runicSelect: null
    };
  },
  computed: {
    usedRefs() {
      if (!this.content.readings) return [];
      let refs = [];
      this.readingReferences.forEach(rr => {
        if (this.content.readings.some(r => r.reference == rr)) refs.push(rr);
      });
      return refs;
    },
    usedLangs() {
      if (!this.content.readings) return {};
      let langs = {};
      this.readingReferences.forEach(rr => {
        if (this.content.readings.some(r => r.reference == rr)) {
          let ri = this.content.interpretations
            .filter(i => i.readingReference == rr)
            .map(i => i.language.id);
          let rt = this.content.translations
            .filter(t => t.readingReference == rr)
            .map(t => t.language.id);
          langs[rr] = {
            interpretations: ri,
            translations: rt
          };
        }
      });
      return langs;
    },
    nextRef() {
      return this.readingReferences[this.usedRefs.length];
    },
    currentReading() {
      if (!this.content.readings) return {};
      return this.content.readings.filter(
        x => x.reference == this.currentRef
      )[0];
    },
    currentInterpretations() {
      if (!this.content.interpretations) return [];
      return this.content.interpretations
        .filter(x => x.readingReference == this.currentRef)
        .sort(
          (a, b) =>
            this.runicOrder.indexOf(a.language.id) -
            this.runicOrder.indexOf(b.language.id)
        );
    }
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addRef() {
      let newRef = this.nextRef;
      this.content.readings.push({
        reference: newRef,
        text: "",
        teiText: "",
        sources: []
      });
      this.currentRef = newRef;
    },
    addRunic() {
      this.content.interpretations.push({
        readingReference: this.currentRef,
        language: { id: this.runicSelect },
        text: "",
        teiText: "",
        sources: []
      });
      this.runicSelect = null;
    },
    addModern() {
      this.content.translations.push({
        readingReference: this.currentRef,
        language: { id: this.modernSelect },
        text: "",
        teiText: "",
        sources: []
      });
      this.modernSelect = null;
    }
  },
  apollo: {
    modernLanguages() {
      return {
        query: gql`
          query preload {
            modernLanguages {
              id: language
              sv
            }
            runicLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }
          this.modernLanguages = result.data.modernLanguages.sort(
            (a, b) =>
              this.modernOrder.indexOf(a.id) - this.modernOrder.indexOf(b.id)
          );

          this.runicLanguages = result.data.runicLanguages.sort(
            (a, b) =>
              this.runicOrder.indexOf(a.id) - this.runicOrder.indexOf(b.id)
          );
        }
      };
    }
  }
};
</script>
